import './App.css';
import {BrowserRouter as Router} from "react-router-dom"
import About from './Components/About';
import Work from './Components/Work'
import Navbar from './Components/Navbar';
import Services from './Components/Services';
import Process from './Components/Process';
import Customers from './Components/Customers';
import Team from './Components/Team';
import Contact from './Components/Contact';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Router>
     <div className=''>
    
    <Navbar/>
    <About/>
    <Work/>
    <Services/>
    <Process/>
    <Customers/>
    <Team/>
    <Contact/>
    </div>
    </Router>
   

 //   <BrowserRouter>
  //   <Routes>
  //     <Route path="/" element={<About />}/>
  //     <Route path="/work" element={<Work />}/>
  //     <Route path="/services" element={<Services />}/>
  //     <Route path="/process" element={<Process />}/>
  //     <Route path="/customers" element={<Customers />}/>
  //     <Route path="/team" element={<Team />}/>
  //     <Route path="/contact" element={<Contact />}/>

     
  //   </Routes>
  // </BrowserRouter>
  );
}

export default App;
