import React from 'react'
import picture1 from "../Assets/Teampic/Picture1.png"
import picture2 from "../Assets/Teampic/Picture2.png"
const Team = () => {
  return (
    <div className='p-10 bg-black text-white' id='team'>
      <h1 className='text-center font-bold text-6xl p-5'>Team</h1>
      <div className='lg:mx-40 lg:my-6 md:mx-15 md:my-4 mx-10 my-2 flex flex-col'>
        <div className='flex justify-center items-center gap-5'>
          <div className='w-[500px]'>
            <img  data-aos="fade-right"
     data-aos-offset="300"
     data-aos-duration="2000" src={picture1} alt="" className='w-full h-full' />
          </div>
          <div className='lg:text-xl md:text-lg sm:text-md text-sm'>
            <h1  data-aos="fade-right"
     data-aos-offset="300"
     data-aos-duration="2000" className='font-bold'>Kent Corbell, Founder</h1>
            <p  data-aos="fade-right"
     data-aos-offset="300"
     data-aos-duration="2000">”Would you be willing to….”  build a theme-park in Singapore?  Lead a startup in Beijing?  Make a 3-year 	project happen in 1 year?For over 25 years, people have asked Kent to help lead and deliver on projects where the pressures were high and the path to success unclear. Willing to take risks and confident in his	capacity to build teams, Kent has repeatedly said ‘Yes’ and then jumped in head-first. Kent now provides strategic leadership to key clients while growing Elemental to be the partner of choice for the world’s top destinations.</p>
          </div>
        </div>
        <div className='flex justify-center items-center gap-5 mt-5'>
          <div className='w-[650px]'>
            <img  data-aos="fade-right"
     data-aos-offset="300"
     data-aos-duration="2000" src={picture2} alt="" className='w-full h-full' />
          </div>
          <div className='lg:text-xl md:text-lg sm:text-md text-sm'>
            <h1  data-aos="fade-right"
     data-aos-offset="300"
     data-aos-duration="2000" className='font-bold'>Jon-Bentley Wiggins, Partner</h1>
            <p  data-aos="fade-right"
     data-aos-offset="300"
     data-aos-duration="2000">Neal Stephenson wrote in Anathem about a future human crisis where the most valuable people were ‘Educable’, in that they quickly saw what was possible and needed, rapidly learned the details, and then motivated and led other people to work together to save the day. This is JB’s superpower. From USC Film studies, to curating and deploying global corporate art portfolios, to technically and logistically complex projects in NYC and Las Vegas, JB becomes the nucleus of a project focusing energy and attention from all the parts and players into a powerful cohesive whole that is much greater than the sum of its parts.</p>
          </div>
        </div>
        </div>
      
    </div>
  )
}

export default Team
