import React, { useState } from 'react';
import { BiSolidCategory } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import Picture19 from "../Assets/Picture19.png";
import atom from "../Assets/atomic.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div className='p-5 grid grid-cols-3 bg-black text-white'>
      <div className="logo">
        <h1 className='lg:text-4xl md:text-[3xl] sm:text-[2xl] text-md   font-bold cursor-pointer'>
          <a href="#about" className='flex'>AMBITI<img src={atom} alt="" className='lg:w-[40px] md:w-[30px] sm:w-[20px] w-[10px] flex justify-center items-center'/>US PARTNERS</a>
        </h1>
        <p className='md:flex hidden'>World-Class Creative Project Professionals <br />for 
          Entertainment, Experiential, & Digital Architecture
        </p>
      </div>
      <div className="nav-items col-span-2 flex justify-center h-[50%] ">
        <ul className={`lg:flex hidden md:gap-10 lg:gap-16 border-b-2 ${isOpen ? 'hidden' : 'flex'}`}>
          <li className='hover:text-[#007FFF] hover:font-bold'><button onClick={() => scrollToSection("about")}>ABOUT</button></li>
          <li className='hover:text-[#007FFF] hover:font-bold'><button onClick={() => scrollToSection("work")}>WORK</button></li>
          <li className='hover:text-[#007FFF] hover:font-bold'><button onClick={() => scrollToSection("services")}>SERVICES</button></li>
          <li className='hover:text-[#007FFF] hover:font-bold'><button onClick={() => scrollToSection("process")}>PROCESS</button></li>
          <li className='hover:text-[#007FFF] hover:font-bold'><button onClick={() => scrollToSection("customers")}>CUSTOMERS</button></li>
          <li className='hover:text-[#007FFF] hover:font-bold'><button onClick={() => scrollToSection("team")}>TEAM</button></li>
          <li className='hover:text-[#007FFF] hover:font-bold'><button onClick={() => scrollToSection("contact")}>CONTACT</button></li>
        </ul>
        <button className={`lg:hidden flex relative md:left-40 md:top-4 left-20 top-3 sm:left-40 sm:top-4${isOpen ? 'hidden' : 'flex'}`} onClick={toggleSidebar}>
          <BiSolidCategory size={30} />
        </button>
        <button className={`lg:hidden relative md:left-40 md:top-4 z-50 left-20 top-3  sm:left-40 sm:top-4 ${isOpen ? 'flex' : 'hidden'}`} onClick={toggleSidebar}>
          <RxCross2 size={30}/>
        </button>
      </div>
      {isOpen && 
        <div className="lg:hidden bg-[#1B1B1B] shadow-lg z-40 h-screen absolute top-0 right-0 w-[70%] sm:w-[60%] md:w-[40%]">
          <ul className={` flex flex-col justify-center mt-14`}>
            <li className='p-4'><button onClick={() => scrollToSection("about")}>ABOUT</button></li>
            <li className='p-4'><button onClick={() => scrollToSection("work")}>WORK</button></li>
            <li className='p-4'><button onClick={() => scrollToSection("services")}>SERVICES</button></li>
            <li className='p-4'><button onClick={() => scrollToSection("process")}>PROCESS</button></li>
            <li className='p-4'><button onClick={() => scrollToSection("customers")}>CUSTOMERS</button></li>
            <li className='p-4'><button onClick={() => scrollToSection("team")}>TEAM</button></li>
            <li className='p-4'><button onClick={() => scrollToSection("contact")}>CONTACT</button></li>
          </ul>
        </div>
      }
    </div>  
  );
};

export default Navbar;
