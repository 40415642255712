import React from 'react'

const Services = () => {
  return (
    <div  className='lg:mx-32 lg:my-6 md:mx-20 md:my-4 mx-14 my-3 text-white p-2 pb-10 ' id='services'>
      <div className='lg:text-2xl md:text-xl sm:text-lg text-md flex flex-col space-y-4'>
        <h1 data-aos="fade-right" data-aos-duration="2000" className='text-center text-5xl font-bold p-10'>Services</h1>
      <p data-aos="fade-right" data-aos-duration="2000" className='font-semibold text-3xl'>We deliver value from blue-sky concepting through execution and operation</p>
    <ol data-aos="fade-right" data-aos-duration="2000" className=' cursor-default'>
    <li>STRATEGIC PROJECT LEADERSHIP</li>
    <li>Owner/Developer’s Representative</li>
    <li>Project Director / Producer</li>
    <li>Scope Definition, Pricing, Scheduling, & Contracting</li>
    <li>CREATIVE CONCEPTING</li>
    <li>TECHNICAL DIRECTION</li>
    <li>FABRICATION</li>
    <li>INSTALLATION</li>
    <li>OPERATIONS</li>
    <li>ONGOING IMPROVEMENTS</li>
  </ol>
    </div>
    </div>
  )
}

export default Services
