import React from 'react'

const About = () => {
  return (
    <div className=' text-white p-10' id='about'>
        <div className='text-lg md:text-xl lg:text-2xl flex flex-col space-y-4 lg:mx-20 lg:my-6 md:mx-15 md:my-4 mx-10 my-3'>
            <p>Trusted leaders and strategic partners for entertainment, experiential, and digital architecture technology projects since 1998. </p>
            <p>Our clients are owners, brands, architects, agencies, consultants, integrators, and operators.</p>
            <p>With your team and vendors, or ours, we lead and produce concepting, planning, design, engineering, construction, operations, and ongoing improvements.</p>
            <p>Our experience and expertise spans development, construction, architecture, scenic, A/V, control, networks, lighting, special effects, mechatronics, custom fabrication, and content. </p>
            <p>With Collaborative Chemistry™, we deliver creative clarity, technical performance, and operational excellence.</p>
            <p>What we do is elemental, piece by piece, person by person, project by project.  How we do it is panoramic, seeing and sharing the big picture to achieve your project and team’s full potential throughout the process.</p>
            <p>We are your element of success.  What can we help you make real?</p>
        </div>
      
    </div>
  )
}

export default About
