import React from 'react';
import Picture2 from '../Assets/Picture2.png';
import Picture3 from '../Assets/Picture3.png';
import Picture4 from '../Assets/Picture4.png';
import Picture5 from '../Assets/Picture5.png';
import Picture6 from '../Assets/Picture6.png';
import Picture7 from '../Assets/Picture7.png';
import Picture8 from '../Assets/Picture8.png';
import Picture9 from '../Assets/Picture9.png';
import Picture10 from '../Assets/Picture10.png';
import Picture11 from '../Assets/Picture11.png';
import Picture12 from '../Assets/Picture12.png';
import Picture13 from '../Assets/Picture13.png';
import Picture14 from '../Assets/Picture14.png';
import Picture15 from '../Assets/Picture15.png';
import Picture16 from '../Assets/Picture16.png';
import Picture17 from '../Assets/Picture17.png';
import Picture18 from '../Assets/Picture18.png';
import { useState } from 'react';
const Work = () => {
  const [hoverState, setHoverState] = useState({});
  const handleMouseEnter = (index) => {
    setHoverState((prevState) => ({ ...prevState, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setHoverState((prevState) => ({ ...prevState, [index]: false }));
  };
  const images = [
    { src: Picture2, title: 'LINCOLN CENTER NYC', description: 'Challenged to cut the project timeline in half for the NY Philharmonic’s high-profile David Geffen Hall rebuild, AVI-SPL came to us to assemble and lead multiple teams and systems for projection, audio, cinema, networks, and communication.', with: 'With: AVI-SPL, Akustiks, Turner Construction, Lincoln Center', link: 'https://www.lincolncenter.org/venue/david-geffen-hall' },
    { src: Picture3, title: 'ARTE MUSEUM LAS VEGAS', description: 'Korean digital art pioneer D’strict had big ambitions, and a very aggressive schedule, for their first location outside Asia.  With long-time partner AVI-SPL, we led the highly-customized engineering, fabrication, and integration of 130+ projectors and speakers, and their backend network and content generation systems, into 10 unique environments to deliver Arte Museum Las Vegas on-time, in-budget, and to rave reviews.', with: 'With: AVI-SPL, Penta', link: 'https://lasvegas.artemuseum.com/' },
    { src: Picture4, title: 'BELLAGIO FOUNTAINS LAS VEGAS', description: 'The Fountains of Bellagio have been wowing visitors since 1998, when Kent was a core part of the small team that built the 1/3-mile wide sound system not once, but twice.  After flooding damaged the under-sidewalk amp vault and 13,000’ feet of cabling, we had just weeks to replicate months of work, while simultaneously finishing the massive hotel & casino’s many systems.', with: 'With: Steve Wynn, WET, Signal Perfection Ltd, Marnell Carrao', link: 'https://bellagio.mgmresorts.com/en/entertainment/fountains-of-bellagio.html' },
    { src: Picture5, title: 'SHANGHAI DISNEYLAND', description: 'Disney’s partnership with China to build Shanghai Disneyland prompted the rapid development of in-country companies.  Kent was called by members of Imagineer’s trusted partner network to mentor Beijing technology and fabrication startup Themetech.  The challenge was to rapidly scale to meet the conflicting demands of construction timeline and the performance and quality demands of Disney’s engineering, maintenance, and artistic-theming departments.  In 10 months, Kent guided Themetech to transform a young team and an empty shop into a reliable partner that continued to serve Disney, Universal, and the dynamic Asian attractions for years.', with: 'With: Disney Imagineering, Scenario, The Attraction Services Company', link: 'https://www.shanghaidisneyresort.com/en/' },
    { src: Picture6, title: '28 LIBERTY NYC', description: 'The installation of custom LED screens in this LPC Landmarked building presented unique challenges to Technomedia, who sought us to provide technical and project management.', with: 'With: Foson, Technomedia, Big Show', link: 'https://28liberty.com/' },
    { src: Picture7, title: 'FASHION SHOW MALL LAS VEGAS', description: 'Directly with owner The Rouse Company, we created the first moving LED signage on the Las Vegas Strip, and a live-event venue with retracting runway and stage.  Kent directed the 3-year design/build program choreographing architecture, structural engineering, construction, show-action equipment, A/V, lighting, scenic, and show operations.  When the original contractor filed bankruptcy mid-project, Kent hired the team and kept the project going to deliver a venue that’s still in use 23 years later.', with: 'With: The Rouse Company, Hughes Corporation, Whiting-Turner', link: 'https://www.fslv.com/en/events/' },
    { src: Picture8, title: 'UNIVERSAL STUDIOS SINGAPORE', description: 'Lights, Camera, Action Hosted by Steven Spielberg is an immersive disaster attraction at Universal Studios Singapore.  As Attractions Manager for Scenario/IDB, Kent led a large team of architects, designers, engineers, fabricators, content creators, and programmers to develop and stress-test a timed sequence of explosions, crashes, and mayhem that repeats eight times an hour, 10 hours a day, and is still going 13 years later.', with: 'With: Resorts World, Universal Studios, Scenario, KHSS, CW Driver', link: 'https://www.rwsentosa.com/en/attractions/universal-studios-singapore/shows-and-entertainment/lights-camera-action' },
    { src: Picture9, title: 'O THEATER LAS VEGAS', description: 'Having helped deliver the full audio, video, and communications systems for Cirque du Soleil’s O in 1998 as part of SPL, the production team asked Kent back independently to perform open-heart surgery on the front-of-house mix position.  Envisioned and engineered by the late and very great Mark Dennis, this upgrade replaced the sound console with a new modular digital surface, and moved several racks of equipment with nearly 1,000 active cables to a new space under the seating.  With Mark and the CDS team, Kent carefully sequenced and directed a week-long 24/7 operation that delivered a consistent quality audio experience for guests and performers, and 12 new prime center-of-house seats for the regularly sold-out show.', with: 'With: Cirque du Soleil, Bellagio, Level Control Systems (now Meyer Sound)', link: 'https://www.cirquedusoleil.com/o' },
    { src: Picture10, title: 'NBC STUDIOS TOUR NYC', description: 'Down the hall from The Tonight Show w/ Jimmy Fallon, and directly below SNL’s Studio 8H, On Air at NBC Studios in 30 Rockefeller Plaza allows tour guests to be cast and crew on their own late-night talk-show set.  As project and technical director with Technomedia, Kent worked with the NBC Universal team and Technomedia’s staff and vendors to develop and build the concept, the script, the set, and the technology that prompts and captures this unique guest experience.', with: 'With: NBC Univeral, Tishman Speyer, Technomedia, Arch Design & Production', link: 'https://www.thetouratnbcstudios.com/' },
    { src: Picture11, title: 'TIMES SQUARE FLAGSHIP RETAIL', description: 'For T-Mobile’s flagship Times Square store, we were asked by long-time partner AVI-SPL to help coordinate the complexities of building owner, tenants, union construction trades, overnight deliveries into the heart of Manhattan, storage and testing logistics, installation, testing, and training of a 2-story high custom interior wrap-around LED façade.', with: 'With: AVI-SPL, CBRE, Empire State Development Corp, Vornado' },
    { src: Picture12, title: 'FORTUNE 100', description: 'Direct and/or with our partners, we have delivered hundreds of premium amenity spaces for companies including Apple, Amazon, Google, Accenture, ConEdison, InfoSys, Intuit, Nestle, Nike, NYU, NYPD, and many more.  From concept through completion, we lead collaboration with owners, architects, GCs, contractors and subs, vendors, facilities managers, end-users, and corporate offices.' },
    { src: Picture13, title: 'Museums', description: 'We’ve worked with many of the major museums in NYC, direct and/or with our partners.  In Trustee meeting rooms, educational classrooms, and exhibit spaces, we’ve worked closely with in-house leadership to identify and deliver architectural integration and technology that bridge their current and future needs, and serve their disparate stakeholders.' },
    { src: Picture14, title: 'EBC', description: 'With a 270-degree curved LED display, floor-to-ceiling touch screens, and highly-customized software integration, this is a best-in-class Executive Briefing Center for a major cloud telephony provider.  With an ambitious technology design, aggressive schedule, and complicated building and construction logistics, AVI-SPL asked us to lead this project for their teams.  We delivered a high-performance physical installation, and then worked closely with the consultants and operators to provide deep training and collaboration with the end-users so they could realize the potential of their new dynamic spaces.', with: 'With: AVI-SPL, Arup, Avaya, JT Magen, Vornado' },
    { src: Picture15, title: 'MeetingRooms', description: 'For tech, legal, finance, education, government, and more, we have delivered thousands of meeting rooms.  From private offices to town-hall auditoriums, from basement back-office caves to showcase movie-set C-suites, we understand the aesthetics, logistics, and intricacies of successfully serving every single person in your organization.' },
    { src: Picture16, title: 'Spatial Audio', description: 'High-fidelity artist listening rooms with Dolby Atmos or other spatial audio systems are hubs for relationships and dealmaking for major music streaming services.  These feature custom aesthetic integration into ceiling and wall finishes, acoustic treatments, and sophisticated user experiences.  With AVI-SPL, we’ve led the build, tuning, and improvements to these rooms for some of the top services in the music business.', with: 'With: AVI-SPL, Dolby' },
    { src: Picture17, title: 'Zoo', description: 'Opened in 1899, the 265 acres of the Wildlife Conservation Society’s Bronx Zoo features several architecturally distinct, and delicate, buildings on the National Register of Historic Places.  WCS asked AVI-SPL for a new park-wide audio system for paging and revenue-generating seasonal and special events, and AVI-SPL asked us to lead this project with them.  Over 2 years, we worked with every department of the zoo including C-suite, IT, zookeepers, buildings, security, environmental/theming, and operations to install over 220 new speakers across 43 buildings.', with: 'With: AVI-SPL, WCS', link: 'https://bronxzoo.com/' },
    { src: Picture18, title: 'Retail Spaces', description: 'From independent retail spaces, chain stores, department stores, to attraction gift shops, we’ve integrated technology, fixtures, furnishings, and architecture in dozens of spaces.' }
  ];

  return (
    <div id='work' className='flex flex-col items-center mt-10 bg-white text-black '>
      <div className="head">
        <h1 className=' font-bold text-6xl p-10'>Work</h1>
      </div>
      <div className='relative mx-20 my-6 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 lg:gap-14 md:gap-10 sm:gap-5 gap:3 '>
      {images.map((image, index) => (
        <div data-aos="fade-up" key={index} className='relative cursor-pointer'>
            <img src={image.src} alt={image.title} className='w-full h-auto md:max-h-80  sm:max-h-56 max-h-40 object-cover'/>
           {!hoverState[index] && <p className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center text-white lg:text-4xl md:text-3xl sm:text-2xl text-xl text-center font-bold'>{image.title}</p>}
            <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white text-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 ease-in duration-300'
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}>
              <p className='text-[15px]'>{image.description}</p>
              <p className=' text-[12px] '>{image.with}</p>
            </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default Work;
