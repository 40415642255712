import React from 'react'
import Picture19 from "../Assets/Picture19.png"
import atom from "../Assets/atomic.png";
const Process = () => {
  return (
    <div className=' p-14 bg-white' id='process'>
      <div className='text-2xl lg:mx-32 lg:my-6 md:mx-20 md:my-4 mx-14 my-3'>
        <h1  data-aos="zoom-in" data-aos-duration="2000" className='mb-10 text-center font-bold text-6xl'>Process</h1>
        <p  data-aos="zoom-in" data-aos-duration="2000"><i>“All minds have to see the whole task to contribute efficiently.” </i> Oppenheimer (movie)</p>
        </div>
        <div  data-aos="zoom-in" data-aos-duration="2000" className='mt-5'>
          <p>What we do is elemental, piece by piece, person by person, project by project.  How we do it is with Collaborative Chemistry™, seeing and sharing the big picture and the details to achieve your project and team’s full potential throughout the process.</p>
        </div>
        <div  data-aos="zoom-in" data-aos-duration="2000" className='mt-8 text-xl ml-10'>
          <p>Collaborative Chemistry™</p>
        </div>
        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  mt-8 ml-5 px-5'>
          <div  data-aos="zoom-in" data-aos-duration="2000" className='w-[200px] h-[200px] '>
            <img src={atom} alt="" className='w-full h-full' />
          </div>
          <div  data-aos="zoom-in" data-aos-duration="2000" className='lg:text-2xl md:text-xl text-lg col-span-2'>
           <p> - Orbital Overview:  All pieces viewed from all angles</p>
			     <p>- Macro-Micro Dance:  Every detail considered within the whole picture</p>
			    <p>- Robust Communication Structure increasing team focus and flow</p>
			    <p>- Optimize the strengths of each expert and vendor	</p>	
			     <p>- Timeline Control:  See the end from the beginning, speedcollaboration,
				      decrease development time, optimize backward and forward at
				       every stage</p>
          </div>
        </div>
      
    </div>
  )
}

export default Process
