import React from 'react';
import { MdOutlineMail } from "react-icons/md";
const Contact = () => {
  return (
    <div id='contact' className='bg-white py-10 px-14'>
      <p className='font-bold text-center lg:text-5xl md:text-3xl text-2xl'>Contacts</p>
      <div className='py-5 flex items-center gap-1 pl-20 text-xl'>
        <div className=''>
        <MdOutlineMail size={30}/>
        </div>
        <a href='mailto:info@elementalprojects.net' className='underline'>info@elementalprojects.net</a>
      </div>
    </div>
  );
};

export default Contact;
