import React from 'react'
import amazon from "../Assets/Logos/amazon.png"
import amnh from "../Assets/Logos/amnh.jpg"
import apple from "../Assets/Logos/apple.png"
import aqr from "../Assets/Logos/aqr.jpg"
import arup from "../Assets/Logos/arup.jpg"
import avi from "../Assets/Logos/avi-spl.png"
import bellagio from "../Assets/Logos/bellagio.jpg"
import brookfield from "../Assets/Logos/brookfield.png"
import cbre from "../Assets/Logos/cbre.png"
import chase from "../Assets/Logos/chase.png"
import cirque from "../Assets/Logos/cirque-du.jpg"
import disney from "../Assets/Logos/disney.jpg"
import gensler from "../Assets/Logos/gensler.png"
import google from "../Assets/Logos/google.png"
import jll from "../Assets/Logos/jll.png"
import lincoln from "../Assets/Logos/lincoln.jpg"
import nbc from "../Assets/Logos/nbc.png"
import nike from "../Assets/Logos/nike.png"
import nypd from "../Assets/Logos/nypd.png"
import shop from "../Assets/Logos/shop.jpg"
import technomedia from "../Assets/Logos/technomedia.jpg"
import tishman from "../Assets/Logos/tishman.jpg"
import turner from "../Assets/Logos/turner.jpg"
import universal from "../Assets/Logos/universal.png"
import vornado from "../Assets/Logos/vornado.png"
import wcs from "../Assets/Logos/wcs.png"
import whitney from "../Assets/Logos/whitney.png"

const Customers = () => {
  const logoData = [
    { name: "Apple", src: apple },
    { name: "Wildlife Conservation Society", src: wcs },
    { name: "Amazon", src: amazon },
    { name: "NBC", src: nbc },
    { name: "Google", src: google },
    { name: "Chase Bank", src: chase },
    { name: "NYPD", src: nypd },
    { name: "AQR", src: aqr },
    { name: "Lincoln Center", src: lincoln },
    { name: "CBRE", src: cbre },
    { name: "Disney", src: disney },
    { name: "Vornado", src: vornado },
    { name: "Universal Studios", src: universal },
    { name: "Tishman Speyer", src: tishman },
    { name: "Whitney Museum", src: whitney },
    { name: "Brookfield", src: brookfield },
    { name: "American Museum", src: amnh },
    { name: "Arup", src: arup },
    { name: "Bellagio", src: bellagio },
    { name: "Gensler", src: gensler },
    { name: "Cirque du Soleil", src: cirque },
    { name: "SHOP Architects", src: shop },
    { name: "JLL", src: jll },
    { name: "Turner Construction", src: turner },
    { name: "AVI-SPL", src: avi },
    { name: "Technomedia", src: technomedia },
    { name: "Nike", src: nike }
  ];
  return (
    <div className=' text-black bg-white pb-10 pt-10' id='customers'>
      <div className='lg:mx-20  md:mx-15 mx-10'>
      <h1 className='text-center font-bold text-6xl p-2'>Customers</h1>
      <div className='lg:text-2xl md:text-xl text-lg font-semibold mt-10'>
        <p>Directly and/or as contractors and team-members, we have worked with:</p>
      </div>
      <div className='customers'>
        <div className='grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-16 mt-10'>
          {
            logoData.map((logo,index)=>
              (
                <div  key={index} className='w-[120px] h-[120px] hover:scale-110 duration-300 ease-in-out flex flex-col justify-center'>
                  <div className='w-full h-full'>
                  <img src={logo.src} alt=""  className='w-full h-full object-contain'/>
                  </div>
                </div>
              )
            )
          }
        </div>

      </div>
      </div>
    </div>
  )
}

export default Customers
